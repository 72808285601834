import CareerDetail from "../../features/career_detail";

export default (props: Object) => {
  return (
    <CareerDetail
      {...props}
      locales={{
        it: {
          title: "Brand manager",
          seoDescription:
            "Essere lofter significa curiosità, ambizione e passione. Lavoriamo per portare innovazione nel mondo del software e dei prodotti digitali. Ti va di unirti a noi?",
          roles: [
            "Strutturerai campagne di comunicazione per raggiungere i nostri target",
            "Farai proposte e ti attiverai su come aumentare la reputation Mabiloft",
            "Realizzerai strategie online e offline legate al brand, anche coordinando il team per creare contenuti",
            "Darai supporto all'organizzazione di eventi e apertivi che organizziamo a Padova",
            "Cercherai opportunità di crescita e networking per il team (partecipazioni ad eventi, corsi di formazione, convegni)",
            "Tutto quello che pensi sia meglio per far crescere Mabiloft!",
          ],
          prerequisites: [
            "Conoscenza del mondo digital: possiamo insegnarti come funzionano le nostre tecnologie, ma devi avere lo spirito adatto",
            "Conoscenza di tool come Canva o pacchetto Adobe (e in generale avere buon occhio!) ",
            "Buona conoscenza dell'inglese",
          ],
          yearsOfExperience: 1,
        },
      }}
    />
  );
};
